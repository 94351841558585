import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainSection from "../../common/MainSection";
import ParentGrid from "../../common/ParentGrid";
import OrangeBg from "../../img/backgrounds/orange_white.jpg";
import ShopCardBoxes from "../../common/ShopCardBoxes";
import ImgCardLogo from "../../img/card_logo.jpg";

const planItems = [
	{
		title: "Pase Mensual",
		img: ImgCardLogo,
		//link:'https://wa.me/541140358561?text=Hola%20queria%20la%20informacion%20del%20pase%20mensual',
		link: "https://wa.me/541140358561",
		content:
			"Pase libre, válido para asistir a todas las actividades y horarios de forma ilimitada (con excepción de BJJ).",
		price: "49900",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Pase Trimestral",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content:
			"Pase libre, válido para asistir a todas las actividades y horarios de forma ilimitada (con excepción de BJJ). Promoción válida en efectivo",
		price: "144000",
		paymentMethods: ["efectivo"],
	},
	{
		title: "Débito Automático",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "",
		price: "49900",
		paymentMethods: ["efectivo"],
	},
	{
		title: "Clase de Prueba",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Se descuenta del plan elegido",
		price: "8500",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Matricula",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: " Vigencia de 1 año",
		price: "1500",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Clase Individual",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a clase individual",
		price: "11000",
		paymentMethods: ["efectivo"],
	},
];

function Membresias() {
	return (
		<div>
			<MainSection backgroundImage={OrangeBg} pb="150px">
				<ParentGrid>
					<Stack
						width="900px"
						max-width="100%"
						spacing={5}
						justifyContent="center"
						alignItems="center"
					>
						<Typography
							variant="h3"
							component="h3"
							color="black"
							sx={{ textAlign: "center" }}
						>
							Tenemos planes adaptados a todas las necesidades:
						</Typography>
						<ShopCardBoxes plans={planItems} />
					</Stack>
				</ParentGrid>
			</MainSection>
		</div>
	);
}

export default Membresias;
